import React from 'react'
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Section, BreadCrumb, MarkdownContent, MarkdownContentWrapper, MarkdownContentWrapperInner, MarkdownContentTitle, ComingSoon } from '../components/Section'
import PrimaryLinkButton from '../components/Button/PrimaryLinkButton'
import { Link } from "gatsby"
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'
import BreakpointDown from '../components/Media/BreakpointDown'


const GroceryTitle = styled.h1`

`
const GroceryRow = styled.div`
  display:flex;
  margin-left:-15px;
  margin-right:-15px;
  flex-wrap:wrap;
  justify-content:space-between;
  margin-top:20px;
`
const GroceryColumn = styled.div`
 flex:0 0 50%;
 width:50%;
 padding-left:15px;
 padding-right:15px;
 ${BreakpointDown.xl`
  flex:0 0 100%;
  width:100%; 
 `}
 .gatsby-image-wrapper{ width:100%;}
 &:first-child{
   padding-right:60px;
   ${BreakpointDown.xl`
      flex:0 0 100%;
      width:100%;
      padding-right:15px;
    `}
 }
 &:last-child{
   padding-top:40px;
   ${BreakpointDown.md`
     padding-top:0px;
   `}
 }
`


// GroceryIndustry
const SectionBoxShadow = styled(Section)`
  box-shadow: 0px 20px 85px #00000033;
  z-index:2;
`
const GroceryIndustryTitle = styled.h2`
  max-width: 768px;
  text-align:center;
  margin-left:auto;
  margin-right:auto;
`

const GroceryIndustryList = styled.div`
 display:flex;
 flex-wrap:wrap;
 margin-top:60px;
 border-bottom:1px solid #CCCCCC;
 padding-bottom:60px;
 ${BreakpointDown.xl`
   margin-top:30px;
   padding-bottom:30px;
 `}
 &:nth-child(odd){
   flex-direction:row-reverse;
   &:last-child{border-bottom:0; padding-bottom:0;}
   .industry-left{
  &:before{
    right:auto;
    margin-right:0;
    left: 0;
    top: 35px;
    margin-left: -60px;
  }
   }
   .industry-right{
    padding-right: 90px;
    padding-left:0;
    ${BreakpointDown.xl`
      padding-right: 0px;
    `}
   }
 }
`
const GroceryIndustryListLeft = styled.div`
flex:0 0 600px;
position:relative;
${BreakpointDown.xl`
 flex:0 0 100%;
`}
&:before{
  content:'';
  width:120px;
  height:4px;
  background:#000;
  position:absolute;
  right:0;
  top:35px;
  margin-right:-60px;
  z-index:1;
  ${BreakpointDown.xl`
     display:none;
  `}
}
`
const GroceryIndustryListRight = styled.div`
  flex:1;
  padding-left:90px;
  padding-top:20px;
  ${BreakpointDown.xl`
    padding-left: 0px;
  `}
    ${BreakpointDown.md`
     padding-top:10px;
  `}
`
const GroceryIndustryListRightTitle = styled.div`
${BreakpointDown.md`
  margin-bottom:0;
`}
`
const GroceryIndustryListRightContent = styled.div`
 p{
   font-style:normal;
 }
`
const TraingleShape = styled.div`
  width: 0;
  height: 0;
  display: block;
  border-top: 20px transparent solid;
  border-right: 20px transparent solid;
  border-left: 20px transparent solid;
  border-bottom: 20px #fff solid;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -1px;
  margin-left: auto;
  margin-right: auto;
  z-index: 3;
`


function ThermalTemperatureMonitoringPage() {
  return (
    <Layout pageName="technology">
      <SEO
        title="Thermal Tempreture Monitoring Technology and Solutions"
        description="Make your Business environment safer and healthier with WYN Technologies' effective, easy-to-use remote temperature monitoring technology and Solutions. Call now for Easy installation."/>
      <Section pt="174px" pb="80px" bgColor="#F8F8F8" xpt="120px" xpb="60px" mpt="90px" mpb="40px">
        <TraingleShape />
        <div className="container">
          <BreadCrumb>
            <Link to="/">Home</Link><Link to="/#">Thermal Temperature Monitoring Solution</Link>
          </BreadCrumb>
          <GroceryRow>
            <GroceryColumn>
              <GroceryTitle>Thermal Temperature Monitoring Solution</GroceryTitle>
                  <p>After being forced to face the global COVID-19 pandemic, everyone is more keenly aware than ever about the importance of maintaining good health safety practices. And one key health safety protocol that continues to remain important is that of body temperature monitoring. This is especially important if you’re a business owner trying to figure out the best ways to navigate these unprecedented times.</p>
                  <p>Thankfully, prioritizing health safety for your customers and employees doesn’t have to be intimidating. WYN Technologies offers effective, easy-to-use remote temperature monitoring systems to help keep you and your business environment safer and healthier.</p>
    
            </GroceryColumn>
            <GroceryColumn>
              <StaticImage
                src="../images/placholder.jpg"
                quality={100}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Brands We Trust"
                placeholder="blurred"
              />
            </GroceryColumn>
          </GroceryRow>
        </div>
      </Section>
      <SectionBoxShadow bgColor="#F8F8F8" pt="80px" pb="80px" xpt="60px" xpb="60px" mpt="40px" mpb="40px">
        <div className="container">
          <GroceryIndustryTitle className="h1">See how WYN Technologies helping Thermal Temperature Industry</GroceryIndustryTitle>
          <GroceryIndustryList>
            <GroceryIndustryListLeft className="industry-left">
              <StaticImage
                src="../images/placholder.jpg"
                quality={100}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Industry"
                placeholder="blurred"
              />
            </GroceryIndustryListLeft>
            <GroceryIndustryListRight className="industry-right">
              <GroceryIndustryListRightTitle className="h3">Site Surveys</GroceryIndustryListRightTitle>
              <GroceryIndustryListRightContent>
                <p>Before we ever recommend a remote temperature monitoring system – or any type of surveillance system, for that matter – we’ll always start by listening. Whenever you connect with us, we’ll take the time to learn more about you, your organization, and your goals and needs for surveillance technology. We’ll want to come out and take a walk around with you in person, too; that’s the best way for us to make observations and gain some relevant insights to help you with your project. Then we’ll sit down to share some informed recommendations and tips based upon our experience and upon your individual situation.</p>
                <PrimaryLinkButton text="View Full Detail" />
              </GroceryIndustryListRightContent>
            </GroceryIndustryListRight>
          </GroceryIndustryList>
          <GroceryIndustryList>
            <GroceryIndustryListLeft className="industry-left">
              <StaticImage
                src="../images/placholder.jpg"
                quality={100}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Industry"
                placeholder="blurred"
              />
            </GroceryIndustryListLeft>
            <GroceryIndustryListRight className="industry-right">
              <GroceryIndustryListRightTitle className="h3">Free, No-Obligation Product Demos</GroceryIndustryListRightTitle>
              <GroceryIndustryListRightContent>
                <p>Maybe you’ve seen thermal imaging systems in use at another facility, but you’ve not had the opportunity to try them out firsthand and learn how they work for yourself. Not to worry, WYN can make that happen! If you’re new to thermal imaging equipment, or want to learn more about features and potential applications, we can show you a full product demo. We’ll show you what one of these systems can do, and we’ll also help you to visualize some good ways that your business or organization can take full advantage of this powerful technology. We’re here to help!</p>
               {/* <Link to="/"><PrimaryLinkButton text="View Full Detail"></PrimaryLinkButton></Link> */}
              </GroceryIndustryListRightContent>
            </GroceryIndustryListRight>
          </GroceryIndustryList>
          <GroceryIndustryList>
            <GroceryIndustryListLeft className="industry-left">
              <StaticImage
                src="../images/placholder.jpg"
                quality={100}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Industry"
                placeholder="blurred"
              />
            </GroceryIndustryListLeft>
            <GroceryIndustryListRight className="industry-right">
              <GroceryIndustryListRightTitle className="h3">Sales, Installation, & Product Orientation</GroceryIndustryListRightTitle>
              <GroceryIndustryListRightContent>
                <p>WYN is a preferred dealer of thermal imaging cameras from FLIR, along with a full array of quality video surveillance products. We’re more than just vendors, though; we’re actually complete, turnkey solution providers! By partnering with us, you’ll rest easier knowing that we handle everything from equipment staging, to configuration, to installation. We’ll take care of everything, plus we won’t leave you guessing as to how everything works. We’ll also be sure to walk you, your key leaders, and your team through product orientation and training. It’s our goal to set you up for real success, and we’re happy to answer any questions you may have.</p>
               {/* <Link to="/"><PrimaryLinkButton text="View Full Detail"></PrimaryLinkButton></Link> */}
              </GroceryIndustryListRightContent>
            </GroceryIndustryListRight>
          </GroceryIndustryList>
          <GroceryIndustryList>
            <GroceryIndustryListLeft className="industry-left">
              <StaticImage
                src="../images/placholder.jpg"
                quality={100}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Industry"
                placeholder="blurred"
              />
            </GroceryIndustryListLeft>
            <GroceryIndustryListRight className="industry-right">
              <GroceryIndustryListRightTitle className="h3">Ongoing Support Whenever You Need Us</GroceryIndustryListRightTitle>
              <GroceryIndustryListRightContent>
                <p>Ever had a commercial technology provider leave you feeling like you were on your own once they finished the installation and walked out the door? We’ve been on the receiving end of that ourselves, and we know that’s a pretty uncomfortable feeling. Thankfully, you won’t have those worries when you partner with WYN Technologies! Unlike some other providers, we’ll be here for you whenever you may need us. We also offer some convenient “service after the sale” contracts if you’d like us to keep everything maintained for you, too. But anytime you have a question or a problem, you can just give us a call!</p>
                {/* <Link to="/"><PrimaryLinkButton text="View Full Detail"></PrimaryLinkButton></Link> */}
              </GroceryIndustryListRightContent>
            </GroceryIndustryListRight>
          </GroceryIndustryList>
        </div>
      </SectionBoxShadow>
      <Section pt="100px" pb="100px" xpt="60px" xpb="60px" mpt="40px" mpb="40px" bgColor="#F8F8F8">
        <div className="container">
          <MarkdownContentWrapper>
            <MarkdownContentWrapperInner>
              {/* <MarkdownContentTitle>
                <h2 className="h1">Content Heading</h2>
              </MarkdownContentTitle>
              <ComingSoon textAlign="center">Coming Soon...</ComingSoon> */}
              <MarkdownContent>
                  <h2>Ditch Those Handheld Temperature Measurement Methods, & Go Remote!</h2>
                  <p>The ability to take advantage of modern thermal temperature monitoring systems has perhaps never been more important. It’s clear that temperature monitoring is here to stay, but why remote monitoring? Isn’t a handheld thermometer equally as effective?  Remote temperature monitoring is a significantly better option, for a multitude of reasons:</p>
                  <ul>
                    <li>
                    <stong>Keep Your Distance</stong>
                    <p>Thermal temperature monitoring systems are completely hands-free. This is a much safer approach for your employees and customers, as opposed to exposing yourself to the personal risk of having to manipulate a handheld, person-to-person measurement option.</p>
                    </li>
                    <li>
                    <stong>Integrate Your Data</stong>
                    <p>You're easily able to upload your data into compatible health portals.</p>
                    </li>
                    <li>
                    <stong>Stay Precise</stong>
                    <p>WYN Technologies’ temperature monitoring systems accurately read body temperatures within 0.5°F in normal ambient environments.</p>
                    </li>
                    <li>
                    <stong>Get Thorough Results</stong>
                    <p>These systems render more than simply temperature readings; they can be utilized to help pinpoint signs of a fever as well. This is an effective way to help flag any people who may need further examination.</p>
                    </li>
                    <li>
                    <stong>Get Immediate Results</stong>
                    <p>All results are instantly sent to a thermographer as soon as they’re received.</p>
                    </li>
                    <li>
                    <stong>Make It Yours</stong>
                    <p>You can adjust the acceptable temperature range to help account for things like hot weather or other individual temperature anomalies.</p>
                    </li>
                    <li>
                    <stong>Choose Security</stong>
                    <p>While these systems are designed to monitor temperature, they also incorporate facial recognition technology. The dual benefit of this approach is that it can be factored into your overall access point entry strategy.</p>
                  </li>
                  </ul>
                </MarkdownContent>
              <MarkdownContent>
                  <h2>What are the Benefits of Temperature Monitoring Systems?</h2>
                  <p>In high traffic areas, individual temperature monitoring can sometimes be difficult. However, that doesn’t make it any less important. Remote temperature monitoring systems from WYN Technologies help to neutralize those challenges thanks to their convenience and efficiency. Here are more specific benefits of these temperature monitoring systems:</p>
                   <ul>
                  <li>Hands-free application that makes it safer for your employees and customers.</li>
                   <li>Multiple people can be scanned and detected at the same time</li>
                   <li>Captures identities to make fever isolation easier</li>
                    <li>Automatically records and flags any abnormal temperatures</li>
                   </ul>
                </MarkdownContent>
              <MarkdownContent>
                  <h2>Protecting Your Business Starts with Protecting Your Employees</h2>
                  <p>One of your primary responsibilities as a business owner is to look out for the health and safety of your employees. You really can’t run a successful company without maintaining a healthy workforce, after all. This means that you need to take extra precautions to protect your people. One of the easiest ways to do this is to install a thermal temperature monitoring solution. These remote systems from WYN Technologies keep your employees safer by alerting you of anyone entering your location with a fever or fever-like symptoms. By combining this technology with the implementation of sanitizing stations and frequent cleaning practices, you and your employees will both feel more safe, secure, and protected.</p>
                </MarkdownContent>
              <MarkdownContent>
                  <h2>Protecting Your Customers Makes Them Feel Safer and More Welcome</h2>
                  <p>You also have the responsibility of ensuring the safety of your customers as a business owner. Businesses can’t run without employees, but you won’t be able to keep the doors open very long without any customers or clients! Installing one of WYN Technologies’ remote temperature monitoring systems is a great way to keep your business, organization, or retail environment healthier. Your customers will feel safer and more comfortable knowing that you’re taking proactive steps to help screen for any folks who may be displaying fever-like symptoms. Giving your customers this added peace of mind is a prudent strategy for keeping them happy, and keeping them coming back to continue doing business with you.</p>
                </MarkdownContent>
                 <MarkdownContent>
                  <h2>Making Your Business Better Than Ever by Partnering with WYN Technologies</h2>
                  <p>WYN Technologies provides the highest quality temperature monitoring systems. Our thermal temperature monitoring solutions are known to keep workplaces safer for both employees and customers by singling out anyone with a fever or fever-like symptoms. You can trust us to take care of you here at WYN Technologies; our goal is to help make your business better through the provision of many proven technologies and services. We achieve this by prioritizing clear communication, high moral standards, and innovation in everything we do. See what WYN Technologies can do for you by calling <a href="tel:3368990555">(336) 899-0555</a> today!.</p>
                </MarkdownContent>

            </MarkdownContentWrapperInner>
          </MarkdownContentWrapper>
        </div>
      </Section>
    </Layout>
  )
}
export default ThermalTemperatureMonitoringPage
